import { ALL_COUNTRIES_ARRAY } from "../utilis";
import axios from "axios";
export const faqs = [
  {
    question: "كيف اعرف القياس المناسب لي؟",
    answer:
      "قياس سمول يلبس من وزن ٤٥ الى ٦٠ كيلو.\nميديم من وزن ٦٠ الى ٦٨ كيلو.\nلارج من وزن ٧٠ الى ٧٨ كيلو.\nاكس لارج من وزن ٨٠ الى ٩٥.\nاكسين من ٩٥ الى ١١٠ كيلو.",
  },
  {
    question: "مدة توصيل؟",
    answer: "من ٢٤ ساعة الى ٤٨ ساعة من وقت الطلب.",
  },
  {
    question: "في حال أي اشكال في الطلب؟",
    answer: "امكانية الارجاع من غير الحاجة لدفع مبلغ التوصيل.",
  },
];
export interface IPLACEORDER {
  order_url: string;
  address_id: string;
  address_value: string;
  cart: any;
  payment_mode: string; //
  total_price: string;
  mobile_number: string;
  customer_name: string;
  customer_id: string;
  order_instructions: string;
  delivery_fee: string;
  time: string;
  selected_area: string;
  ip_address: string;
  order_type: string;
  campaign_date: string;
  status: string;
  outlet_id: string;
  recipient_id: string;
  exchange_rate: string;
  campaign_type: string;
  isDev: number;
  storeToken: string;
  lid?: string;
  pixel_id?: string;
  userIpInfo?: IProxyIp;
  CUSTOMER_CONFIRMATION_FOR_ORDER?: "Yes" | "No";
}
export const setThemeColor = (shopInfo: any) => {
  document.documentElement.style.setProperty(
    "--background-color-store",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.GENERAL_COLOR_THEME
  );
  document.documentElement.style.setProperty(
    "--DELIVERY_PICKUP_COLOR",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.DELIVERY_PICKUP_COLOR
  );
  document.documentElement.style.setProperty(
    "--HIGHLIGHTED_BACKGROUND_COLOR",
    shopInfo?.resto_meta?.BUSINESS_COLORS?.HIGHLIGHTED_BACKGROUND_COLOR
  );
  // DELIVERY_PICKUP_COLOR
};
export const social_name = {
  f: "facebook",
  i: "instagram",
  w: "whatsapp",
  g: "google",
  t: "TikTok",
  s: "Snapchat",
};
export type ObjectKey = keyof typeof social_name;
export type ObjectKeyLng = keyof { en: string; ar: string };

export const genShortLink = async (mapUrl: string, token: string) => {
  const url = "https://signalhubapi.dukany.io/api/genShortLink";
  var shortUrl = "";
  const headers = {
    "Content-Type": "text/plain",
    Authorization: `Bearer ${token}`,
  };
  console.log(mapUrl);
  const data = {
    mapUrl: mapUrl,
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(response.data);
    if (response.data) {
      shortUrl = response.data.shortLink;
    }
    return shortUrl;
  } catch (error) {
    console.error("Error generating short link:", error);
  }
};

// export const tempData: SingleItem = {
//   active: "Yes",
//   actual_price: "0",
//   arabic_name: "Passion",
//   colors: [],
//   sizes: [],
//   description:
//     "2 roses, 2 baby rose, 2 asltromeria, 2 hypericum and greeneries",
//   description_ar:
//     "2 roses, 2 baby rose, 2 asltromeria, 2 hypericum and greeneries",
//   description_en:
//     "2 roses, 2 baby rose, 2 asltromeria, 2 hypericum and greeneries",
//   discount_amount: 0,
//   discount_type: "",
//   english_name: "Passion",
//   gallery: [],
//   id: 830,
//   extra_options: {
//     "Choose Size": {
//       id: 94,
//       is_mandatory: "Yes",
//       mandatory_amount: 1,
//       name: "Choose Size",
//       price: 0,
//       items: [
//         {
//           actual_price: 100,
//           discount_price: 100,
//           id: 286,
//           item_type: "option",
//           name: "<b>Standard</b><br>2 roses, 2 baby rose, 2 asltromeria, 2 hypericum and greeneries",
//           price: 100,
//           sub_items: "",
//         },
//         {
//           actual_price: 200,
//           discount_price: 200,
//           id: 287,
//           item_type: "option",
//           name: "<b>Delux</b><br>4 roses, 4 baby roses, 4 alstromeria, 4 hypericum and greeneries",
//           price: 200,
//           sub_items: "",
//         },
//         {
//           actual_price: 280,
//           discount_price: 280,
//           id: 288,
//           item_type: "option",
//           name: "<b>Premium</b><br>8 roses, 8 baby roses, 8 alstromeria or available seasonals, 8 hypericum and greeneries",
//           price: 280,
//           sub_items: "",
//         },
//       ],
//     },
//   },
//   is_customized: "Yes",
//   is_discount: "No",
//   main_image:
//     "https://meemappaws.imgix.net/meemcdn/19-nb-flowers/passion-main_image-1635235872?fm=webp&h=500&w=500&q=100&fit=center&crop=center",
//   name: "Passion",
//   price: "0",
//   rating: 0,
//   shared_unique_id: "ed3b2755-2cf8-11ec-a838-261230127655",
//   show_recipe_main_price: 0,
//   thumbnail:
//     "https://meemapp-order.s3.ap-southeast-1.amazonaws.com/meemcdn/19-nb-flowers/passion-main_image-1635235872",
// };
//
export interface IProxyIp {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
}
export interface StoreData {
  address: string;
  arabic_name: string;

  backgroud_image: string;
  city: string;
  closing_timing: number;
  comments: number;
  country: number;
  country_code: number;
  country_short_name: string;
  description: { en: string; ar: string };
  cover_image: string;
  default_color: string;
  default_lang: string;
  social_fb: string;
  social_geo_location: string;
  social_insta: string;
  social_site: string;
  social_tiktok: string;
  sp_whatsapp_number: string;
  sp_phone_number: string;
  delivery_time_range: number;
  delivery_zones: DeliveryZones;
  discounts: string[];
  email: string;
  english_name: string;
  gallery: any;
  id: number;
  is_pick_available: string;
  latitude: number;
  logo: string;
  longitude: number;
  main_image: string;
  min_basket_price: number;
  name: any;

  opening_timing: number;
  outlet_countries: string[];
  outlets: OutLets;
  payment_method: string;
  phone: number;
  place: string;
  rating: number;
  resto_meta: StoreMeta;
  resto_unique_name: string;
  reviews: number;
  shared_unique_id: string;
  short_description: string;
  site_logo: string;
  special_offers: number;
  time_zone: string;
  whatsapp_number: number;
  whole_order_discounts: number;
}
export interface StoreMeta {
  CUSTOMER_CONFIRMATION_FOR_ORDER?: "Yes" | "No";
  GEN_BUSINESS_HOURS: string;
  BILLING_GATEWAY: [{ id: number; value: string }];
  BUSSINESS_CCY_LANG: { en: string; ar: string };
  BUSSINESS_TYPE: string;
  BUSSINESS_CCY: string;
  DEFAULT_DELIVERY_FEE: string;
  ENABLE_WHATSAPP_FLOATING_BUTTON: string;
  ENABLED_PRODUCT_FAQS: string;
  DISABLED_LOCATION_SERVICES: string;
  DISPLAY_TAX_INFO: string;
  GOOGLE_CONVERSION_FOR_PURCHASE_ORDER: string;
  GOOGLE_GTAG_FOR_ALL_PAGES_CODE: string;
  GOOGLE_VARIFICATOIN_CODE: string;
  RECIPE_IMAGE_DISPLAY: string;
  TERM_AND_CONDITIONS: string;
}
interface OutLets {
  [key: string]: OutLetsObj;
}
interface OutLetsObj {
  area: { [key: string]: Area };
  business_hours: any;
  closed_days: { [key: string]: string[] };
  delivery_fee?: number;
  delivery_time: string;
  discounts: [];
  estimated_time: string;
  out_let_phone_number: string;
  outlet_address: string;
  outlet_address_arabic: string;
  outlet_area: string;
  outlet_country: string;
  outlet_country_short_name: string;
  outlet_delivery: number;
  outlet_delivery_payment_methods: string;
  outlet_dining_payment_methods: string;
  outlet_id: number;
  outlet_image: string;
  outlet_meta: { BILLING_GATEWAY: [{ id: number; value: string }] };
  outlet_name: string;
  outlet_pickup: number;
  outlet_pickup_payment_methods: string;
  pickup_time: string;
  time_zone: string;
  whole_order_discounts: number;
}
export const BussinessTypes = {
  florist: "Florist",
};
export interface SelectedOulet extends OutLetsObj {
  area_name: string;
  delivery_fee: number;
  min_basket_price: number;
  deliveryZone: DeliveryZoneObj;
  selectedOutLetName: string;
  selectedLocationName: string;
}
interface Area {
  area_name: string;
  delivery_fee: number;
  min_basket_price: number;
}
interface DeliveryZones {
  [key: string]: DeliveryZoneObj;
}
export interface DeliveryZoneObj {
  area_id: number;
  area_name: string;
  lat_lng: string;
  outlet_id: number;
  outlet_name: string;
}
export interface Categories {
  arabic_name: string;
  english_name: string;
  id: number;
  main_image: string;
  name: string;
  recipes?: Recipes[];
  resto_id: number;
  thumbnail: string;
}
export interface Recipes {
  active: string;
  arabic_name: string;
  description: string;
  description_ar: string;
  english_name: string;
  exclude_outlets: string;
  extra_price: string;
  id: number;
  img_popup: string;
  is_customized: string;
  name: string;
  price: number;
  rating: number;
  shared_unique_id: string;
  thumbnail: string;
}
export interface SingleItem {
  active: string;
  actual_price: string;
  arabic_name: string;
  colors: [];
  sizes: [];
  description: string;
  description_ar: string;
  description_en: string;
  discount_amount: number;
  discount_type: string;
  color_image: colorsNewObj[];
  english_name: string;
  extra_options: { [key: string]: SingleItemExtras };
  gallery: [];
  id: number;
  product_faqs: ProductFaqsObj[];
  is_customized: string;
  is_discount: string;
  main_image: string;
  name: string;
  sPageEnableOTP: string;
  sPageDeliveryFee: any[];
  price: string;
  rating: number;
  shared_unique_id: string;
  show_recipe_main_price: number;
  thumbnail: string;
}
interface colorsNewObj {
  color: string;
  img_url: string;
}
interface ProductFaqsObj {
  answer: string;
  created_at: string;
  deleted_at: string;
  id: number;
  product_id: number;
  question: string;
  updated_at: string;
}
interface SingleItemExtras {
  id: number;
  is_mandatory: string;
  items: ItemExtrasDetail[];
  mandatory_amount: number;
  name_lang: any;
  name: string;
  price: number;
}
interface ItemExtrasDetail {
  actual_price: number;
  discount_price: number;
  id: number;
  item_type: string;
  name_lang: any;
  name: string;
  price: number;
  sub_items: string;
}
export interface OrderResponse {
  message: string;
  order_id: number;
  order_ref: number;
  payment_link: string;
  tracking_id: string;
}
export interface CustomerInfo {
  customer_id: number;
  message: string;
  new_user: boolean;
}
// response for when user is not created;
/**
 {
    "type": "success",
    "data": {
        "message": "Mujtaba is saved successfully",
        "customer_id": 361,
        "new_user": false
    },
    "message": ""
}
 */
//  var replaceWith = data.dialCode;
// var cc = data.dialCode;
// console.log(
//   "-------->" +
//     phone.replace(
//       new RegExp(cc + "0", "i"),
//       replaceWith
//     )
// );
/**
 * const governArray = [
    { id: "5000", name: "بغداد" },
    { id: "8000", name: "الأنبار" },
    { id: "8000", name: "بابل" },
    { id: "8000", name: "أربيل" },
    { id: "8000", name: "البصرة" },
    { id: "8000", name: "حلبجة" },
    { id: "8000", name: "دهوك" },
    { id: "8000", name: "القادسية" },
    { id: "8000", name: "ديالى" },
    { id: "8000", name: "ذي قار" },
    { id: "8000", name: "السليمانية" },
    { id: "8000", name: "صلاح الدين" },
    { id: "8000", name: "كركوك" },
    { id: "8000", name: "كربلاء" },
    { id: "8000", name: "المثنى" },
    { id: "8000", name: "ميسان" },
    { id: "8000", name: "النجف" },
    { id: "8000", name: "نينوى" },
    { id: "8000", name: "واسط" },
  ];
 * 
 * 
 */
// for validating the phone number
export const isNotValidNumber = (phoneNumber: string, countryCode: string) => {
  /** */
  var isNotValid = false;
  // console.log(phoneNumber,countryCode);
  // finding the index from all country array
  const findIndex = ALL_COUNTRIES_ARRAY.findIndex(
    (item) => item.phone == countryCode
  );

  // country  number length with
  var actulaPhoneLength = ALL_COUNTRIES_ARRAY[findIndex].phoneLength!;
  // console.log(actulaPhoneLength,"...actulaPhoneLength");
  var startsWithZero = phoneNumber.startsWith(countryCode + "0");
  // ignoring zero at the start
  var validatePhone = phoneNumber;
  // console.log('....s',startsWithZero,'...phonenumber...',phoneNumber)
  if (startsWithZero) {
    validatePhone = phoneNumber.replace(
      new RegExp(countryCode + "0", "i"),
      countryCode
    );
  }
  // console.log(validatePhone,"...validatePhone");
  // ignoring country code
  var userPhoneLength = validatePhone.length - countryCode.length;
  // console.log(userPhoneLength,"...userPhoneLength");
  if (validatePhone.length === 0 || userPhoneLength !== actulaPhoneLength) {
    // console.log("...incondition",);
    // setShowError(true);
    isNotValid = true;
  }
  return { isNotValid, validatePhone };
};

export const closeBussinessPage = () => {
  // popup_slide_remove
  // popup_slide
  // bussinessInfoClass
  // const element = document.querySelector(".bussinessInfoClass");
  const element = document.querySelector(".popup_right_animation");
  // const slide_bg_class = document.querySelector(".pop_main_menu_bg");
  // element?.setAttribute('animation-name', 'left');
  //@ts-ignore
  // const animationName = element.style.animationName;

  //@ts-ignore
  // element.style.animationName = 'left';

  //   if (animationName === 'right'){
  //     //@ts-ignore
  //    element.style.animationName = 'left';
  //      }
  // console.log(animationName);
  // popup_right_animation_close
  element?.classList.remove("popup_right_animation");
  element?.classList.add("popup_right_animation_close");

  // slide_bg_class?.classList?.add("pop_main_menu_bg_remove");
};
